@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');
body {
    padding: 0;
    margin: 0;
    background-color: #ffffffe7;
    color: black;
    font-family: 'DM Mono', monospace;
}

a {
    /* this will get rid of the underline in anchor tags */
    text-decoration: none;
}

.card-list {
    /* felx will make all the elements within the card-list section fit in the same row */
    display: flex;
    padding: 1.5rem;
    overflow-x: scroll;
    /* box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) */
}

/* pseudo elements for the scrollbar, pseudo elements are not supported in every browser */

.card-list::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.card-list::-webkit-scrollbar-thumb {
    background: #b9b9b8;
    border-radius: 10px;
    /* inset will put the shadow in the inside of the element */
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, .25), inset -2px -2px 2px rgba(0, 0, 0, .25);
}

.card-list::-webkit-scrollbar-track {
    /* background: linear-gradient(90deg, #201c29, #201c29, #17141d 0, #17141d); */
    background: transparent;
}

.card {
    /* Card sizing */
    height: 350px;
    width: 300px;
    min-width: 250px;
    padding: 1.5rem;
    /*Shadow color and positioning */
    /* background: #17141d; */
    background: #f9fcf9;
    /* the negative rem is the horizontal offset if the shadow it will put the shadow on the left of the card above the right side from the card on the left */
    /* box-shadow: -1rem 0 3rem #000; */
    box-shadow: -.25rem 0 .5rem #000;
    /* border radius makes the borders round */
    border-radius: 18px;
    /* display stayle and column flex */
    display: flex;
    flex-direction: column;
    /* having the transition will make it so that whenever a property changes in the card it will take .2s */
    transition: .2s;
    margin: 0;
    scroll-snap-align: start;
    clear: both;
    position: relative;
}

.card p {
    font-size: 12px;
    margin: 1rem 0 0;
    color: black;
}

.card img {
    width: 200px;
    height: 150px;
    /* filter: grayscale(100%); */
    border-radius: 15px;
}

.card:hover img {
    filter: grayscale(0%);
}

/* the ~ is the general sibling combinator, it will select all the siblings after the hovered card */

.card:hover~.card {
    transform: translateX(50px);
}

.card:hover {
    transform: translateY(-1rem);
}

/* :not is the negation pseudo class this will add the margin to every card except the first child */

.card:not(:first-child) {
    margin-left: -50px;
}

/*Custom card styling for regular grid view */

.card-list1 {   
    /* padding: 1.5rem; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));   
}

.card1 {
    height: 350px;
    width: 250px;
    padding: 1.5rem;
    background: #f9fcf9;
    box-shadow: 0 0 .5rem #000;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    transition: .2s;
    margin-bottom: 1rem;
    scroll-snap-align: start;
    clear: both;
    position: relative;
}

.card1 p {
    font-size: 12px;
    margin: 1rem 0 0;
    color: black;
}

.card1 img {
    width: 200px;
    height: 150px;
    border-radius: 15px;
}

.card1:hover img {
    filter: grayscale(0%);
}

.card1:hover {
    transform: translateY(-1rem);
}

/* End custom card1 */

.card-header {
    margin-bottom: auto;
}

.card-header p {
    font-size: 14px;
    margin: 0 0 1rem;
    width: fit-content;
    color: #7a7a8c;
}

.card-header h3 {
    font-size: 12px;
    margin: 1rem 0 0;
    color: black;
}

.card-header h2 {
    font-size: 15px;
    margin: .25rem 0 auto;
    text-decoration: none;
    color: inherit;
    border: 0;
    display: inline-block;
    cursor: pointer;
}

.card-header h2:hover {
    /* this trick will make the background of the text a gradient color while having the background take the shape of the text and making the text itself transparent */
    /* background: linear-gradient(90deg, #ff8a00, #e52e71); */
    background: linear-gradient(90deg, #00ffc8, #35dd26);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

/* We can make the card author section a grid to display all the elements inside given its grid properties */

.card-author {
    margin: 3rem 0 0;
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    position: relative;
}

.author-avatar {
    grid-area: auto;
    align-self: start;
    position: relative;
    box-sizing: border-box;
}

/* images are inline by default so we want to explicitly make it a block */

.author-avatar img {
    width: 40px;
    height: 40px;
    /* 50% border radius will make it a circle */
    border-radius: 50%;
    /* filter to make it black and white */
    filter: grayscale(100%);
    display: block;
    overflow: hidden;
    margin: 16px 10px;
}

.half-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 48px;
    fill: none;
    /* stroke will give it a border */
    stroke: #ff8a00;
    stroke-width: 8;
    stroke-linecap: round;
    pointer-events: none;
}

.author-name {
    grid-area: auto;
    box-sizing: border-box;
}

.author-name-prefix {
    font-style: normal;
    font-weight: 700;
    color: #7a7a8c;
}

.tags {
    padding: .5rem 2rem 1rem;
    position: absolute;
    bottom: 0;
    right: 0;
}

.tags a {
    font-style: normal;
    font-weight: 700;
    font-size: .5rem;
    color: white;
    text-transform: uppercase;
    font-size: .66rem;
    border-radius: 2rem;
    padding: .2rem .85rem .25rem;
    position: relative;
}

.tags a:hover {
    /* background: linear-gradient(90deg, #ff8a00, #e52e71); */
    background: linear-gradient(90deg, #00ffc8, #35dd26);
}